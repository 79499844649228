import { Injectable, Injector, Inject } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Subject, Observable, throwError } from 'rxjs';
import { map, first, switchMap, catchError } from 'rxjs/operators';
import { AuthOptionsInterceptor } from './auth-options.interceptor';
import { AuthOptions } from '../tokens';
import { AuthService } from '../services/auth.service';
// Referência : https://github.com/vigneshsithirai/Angular-Interceptor/blob/master/src/app/interceptor/httpconfig.interceptor.ts
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Is refresh token is being executed
   */
  private refreshInProgress = false;

  /**
   * Notify all outstanding requests through this subject
   */
  private refreshSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private injector: Injector,
    @Inject(AuthOptions) private config: AuthOptionsInterceptor
  ) { }

  /**
   * Intercept an outgoing `HttpRequest`
   */
  public intercept(
    req: HttpRequest<any>,
    delegate: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.skipRequest(req)) {
      return delegate.handle(req);
    }

    return this.processIntercept(req, delegate);
  }

  /**
   * Process all the requests via custom interceptors.
   */
  private processIntercept(
    original: HttpRequest<any>,
    delegate: HttpHandler
  ): Observable<HttpEvent<any>> {
    const clone: HttpRequest<any> = original.clone();

    return this.request(clone)
      .pipe(
        switchMap((req: HttpRequest<any>) => delegate.handle(req)),
        catchError((res: HttpErrorResponse) => this.responseError(clone, res))
      );
  }

  /**
   * Request interceptor. Delays request if refresh is in progress
   * otherwise adds token to the headers
   */
  private request(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (this.refreshInProgress) {
      return this.delayRequest(req);
    }

    return this.addToken(req);
  }

  /**
   * Failed request interceptor, check if it has to be processed with refresh
   */
  private responseError(
    req: HttpRequest<any>,
    res: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    const authService: AuthService =
      this.injector.get<AuthService>(AuthService);

    const refreshShouldHappen: boolean = authService.refreshShouldHappen(res);

    if (refreshShouldHappen && !this.refreshInProgress) {
      this.refreshInProgress = true;

      authService
        .refreshToken()
        .subscribe(
          () => {
            this.refreshInProgress = false;
            this.refreshSubject.next(true);
          },
          () => {
            this.refreshInProgress = false;
            this.refreshSubject.next(false);
          }
        );
    }

    if (refreshShouldHappen && this.refreshInProgress) {
      return this.retryRequest(req, res);
    }

    return throwError(res);
  }

  /**
   * Add access token to headers or the request
   */
  private addToken(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    const authService: AuthService =
      this.injector.get<AuthService>(AuthService);

    return authService.getAccessToken()
      .pipe(
        map(({ accessToken }) => {
          if (accessToken) {
            let setHeaders: { [name: string]: string | string[] };

            setHeaders = { Authorization: `Bearer ${accessToken}` };

            return req.clone({ setHeaders });
          }

          return req;
        }),
        first()
      );
  }

  /**
   * Delay request, by subscribing on refresh event, once it finished, process it
   * otherwise throw error
   */
  private delayRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.refreshSubject.pipe(
      first(),
      switchMap((status: boolean) =>
        status ? this.addToken(req) : throwError(req)
      )
    );
  }

  /**
   * Retry request, by subscribing on refresh event, once it finished, process it
   * otherwise throw error
   */
  private retryRequest(
    req: HttpRequest<any>,
    res: HttpErrorResponse
  ): Observable<HttpEvent<any>> {
    const http: HttpClient =
      this.injector.get<HttpClient>(HttpClient);

    return this.refreshSubject.pipe(
      first(),
      switchMap((status: boolean) =>
        status ? http.request(req) : throwError(res || req)
      )
    );
  }

  /**
   * Checks if request must be skipped by interceptor.
   */
  public skipRequest(req: HttpRequest<any>) {
    const interceptUrls = this.config.URLs;
    // As URLs serão ignoradas pelo interceptor, isso possibilita que o request seja realizado sem a autenticação de um usuário.
    // Caso o usuário esteja autenticado o token não será acrescentado no header do request.
    if (interceptUrls !== null || interceptUrls !== undefined) {

      const contains = Array.from(interceptUrls).some((url: RegExp) => {
        return url.test(req.url);
      });

      return !contains;
    }

    return true;
  }
}
