import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Token } from '../models/Token';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/User';
import { LoginApiUrl } from '../tokens';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient,
    @Inject(LoginApiUrl) private loginGatewayUrl: string,
    // @Inject(UserApplication) private user: User,
  ) {
  }

  authenticateApplication(user: User): Observable<any> {
    // console.log(user);
    return this.http.post<any>(this.loginGatewayUrl, user)
      .pipe(
        map((token: Token) => {
          localStorage.setItem('@PaymentDashboard-loginTime', new Date().toISOString());
          localStorage.setItem('@PaymentDashboard-token', JSON.stringify(token));
          return token;
        })
      );
  }

  refreshToken(): Observable<any> {
    // return this.authenticateApplication();
    return null;
  }

  refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  isAuthenticated(): boolean {
    const token = JSON.parse(localStorage.getItem('@PaymentDashboard-token'));
    return token && !this.isTokenExpired() ? true : false;
  }

  isTokenExpired(): boolean {
    const token = JSON.parse(localStorage.getItem('@PaymentDashboard-token')) as Token;
    const loginTime = new Date(localStorage.getItem('@PaymentDashboard-loginTime'));
    if (!token || !loginTime) { return undefined; }
    const date = loginTime.setSeconds(loginTime.getSeconds() + token.expiresIn);
    return !(date.valueOf() > new Date().valueOf());
  }

  getAccessToken(): Observable<any> {
    const token = JSON.parse(localStorage.getItem('@PaymentDashboard-token'));
    return of(token);
  }

  logout() {
    localStorage.clear();
  }

}
