/**
 * @export
 * @publicApi
 * @description Configuração para o módulo de autenticação. Esse objeto deve ser passado como parâmetro
 * para o método estático `forRoot` no módulo `AuthenticationModule`.
 * Também pode ser gerado através da classe `AuthenticationOptionsBuilder`.
 */
export class AuthOptionsInterceptor {
  /**
   * @description As URLs serão ignoradas pelo interceptor, isso possibilita que o request seja realizado
   * sem a autenticação de um usuário. Caso o usuário esteja autenticado o token não será acrescentado no header do request.
   * @memberof IAuthenticationOptions
   */

  private interceptedURLs?: Set<RegExp>;

  constructor() {
    this.interceptedURLs = new Set();
  }


  get URLs(): any {
    return this.interceptedURLs.values();
  }

  setURL(key: RegExp) {
    if (key === null || key === undefined) {
      throw new Error('A url não pode ser um valor null ou undefined.');
    }
    this.interceptedURLs.add(key);
  }
}
