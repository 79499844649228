import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

import localePT from '@angular/common/locales/pt';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { GatewayApiUrl, LoginApiUrl, Authorization, PaymentApiUrl, UserApplication, AuthOptions } from './tokens';
import { PaymentService } from './services/payment.service';
import { AuthOptionsInterceptor } from './interceptors/auth-options.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthCheckInterceptor } from './interceptors/auth-check.interceptor';
registerLocaleData(localePT);

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    PaymentService,
    {
      provide: LoginApiUrl,
      useValue: environment.loginApiUrl
    },
    {
      provide: PaymentApiUrl,
      useValue: environment.paymentApiUrl
    },
    {
      provide: Authorization,
      useValue: environment.authorization
    },
    {
      provide: UserApplication,
      useFactory: () => {
        return {
          username: environment.username,
          password: environment.password,
          cnpj: environment.cnpj,
        };
      }
    },

    {
      provide: AuthOptions,
      useFactory: () => {
        const definitions = new AuthOptionsInterceptor();
        // Urls que serão interceptadas e aplicado o token de autenticação no header da requisição.
        definitions.setURL(new RegExp(environment.paymentApiUrl));
        return definitions;
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthCheckInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
})
export class CoreModule { }
