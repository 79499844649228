import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxErrorsModule } from '@hackages/ngxerrors';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DialogModule } from 'primeng/dialog';
import { TableComponent } from './components/table/table.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HeaderComponent } from './components/header/header.component';
export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    SafeHtmlPipe,
    CnpjPipe,
    TableComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    RouterModule,
    ToastModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ProgressSpinnerModule,
    DialogModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    SafeHtmlPipe,
    CnpjPipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    RouterModule,
    ToastModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    ProgressSpinnerModule,
    DialogModule,
    NgxMaskModule,
    TableComponent,
    HeaderComponent
  ]
})
export class SharedModule { }
