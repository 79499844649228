import { InjectionToken } from '@angular/core';
import { AuthOptionsInterceptor } from './interceptors/auth-options.interceptor';
import { User } from './models/User';

export const GatewayApiUrl = new InjectionToken<string>('GatewayApiUrl');

export const LoginApiUrl = new InjectionToken<string>('LoginApiUrl');

export const PaymentApiUrl = new InjectionToken<string>('PaymentApiUrl');

export const Authorization = new InjectionToken<string>('Authorization');

export const UserApplication = new InjectionToken<User>('UserApplication');

export const AuthOptions = new InjectionToken<AuthOptionsInterceptor>('AuthOptions');
