import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaymentApiUrl } from '../tokens';
import { Observable } from 'rxjs';
import { Barcode, PrecheckoutResponse } from '../models/Barcode.model';

@Injectable({
  providedIn: 'root'
})

export class PaymentService {

  private clientModel: {
    name: string;
    cpf: string;
    email: string;
    phoneNumber: string;
    barcodes: string[];
    transactionCode: string;
  };

  constructor(
    private http: HttpClient,
    @Inject(PaymentApiUrl) private paymentApiUrl: string,
  ) {
  }

  getBarcode(barcode: string): Observable<Barcode> {
    return this.http.get<Barcode>(`${this.paymentApiUrl}/barcode/${barcode}`);
  }

  precheckout(barcodeList: { barcodes: string[] }) {
    return this.http.post<PrecheckoutResponse>(`${this.paymentApiUrl}/checkout`, barcodeList);
  }

  payment(token: string): Observable<PaymentResponse> {
    this.clientModel.transactionCode = token;
    return this.http.post<PaymentResponse>(`${this.paymentApiUrl}/checkout`, this.clientModel);
  }
}
