import { Injectable, Injector, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { switchMap } from 'rxjs/operators';
import { AuthOptionsInterceptor } from './auth-options.interceptor';
import { AuthOptions, LoginApiUrl } from '../tokens';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthCheckInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    @Inject(LoginApiUrl) private loginApiUrl: string,
    @Inject(AuthOptions) private config: AuthOptionsInterceptor
  ) { }

  public intercept(req: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
    return this.processIntercept(req, delegate);
  }

  private processIntercept(req: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
    const authService: AuthService = this.injector.get<AuthService>(AuthService);
    if (authService.isAuthenticated() || this.skipRequest(req)) {
      return delegate.handle(req);
    }
    // return authService.authenticateApplication().pipe(switchMap(() => delegate.handle(req)));
  }

  public skipRequest(req: HttpRequest<any>) {
    const interceptUrls = this.config.URLs;
    if ((interceptUrls !== null || interceptUrls !== undefined) && req.url.indexOf(this.loginApiUrl) < 0) {
      const contains = Array.from(interceptUrls).some((url: RegExp) => url.test(req.url));
      return !contains;
    }
    return true;
  }
}
