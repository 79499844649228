import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()

export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredRole: string = route.data.roles ? route.data.roles : '';
    // const hasRole = (!requiredRole || this.authService.hasRole(requiredRole));
    if (this.checkAuthentication()) {
      // this.router.navigate(['/boletos']);
      return true;
    }

    return this.checkAuthentication();
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    const requiredRole: string = route.data.roles ? route.data.roles : '';
    // const hasRole = (!requiredRole || this.authService.hasRole(requiredRole));
    if (this.checkAuthentication()) {
      this.router.navigate(['/']);
      return;
    }

    return this.canActivate(route);
  }

  checkAuthentication(): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login'], { queryParams: { access: false } });
      return false;
    }

    if (this.authService.isTokenExpired()) {
      this.router.navigate(['/login'], { queryParams: { expired: false } });
      return false;
    }

    return true;
  }

}
