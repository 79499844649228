<header class="header">
  <section class="header-content">
    <img class="logo" src="assets/images/logo.png" alt="Parcelai">

    <ul class="header-menu">
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      <li><a href="javascript:;"></a></li>
      
      <li role="menuitem">
        <a (click)="onLogout()">
          <i class="fa fa-fw fa-sign-out"></i>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </section>
</header>
